<template>
    <div class='nvaHead'>
        <div v-if="goback" class="backIcon" @click="toBack">
            <svg-icon icon-class="back" />
        </div>
        <div class="pageTitle">{{title}}</div>
    </div>
</template>

<script>

export default {
    components: {
        
    },
    data() {
        return {

        };
    },
    props:['goback', 'title'],
    created() {
        
    },
    mounted() {
        
    },
    methods: {
        toBack() {
            this.$router.back()
        }
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.nvaHead {
    width: 100%;
    height: 45px;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    z-index: 99999;
    .backIcon {
        position: absolute;
        left: 15px;
        font-size: 20px;
    }
    .pageTitle {
        font-size: 16px;
    }
}
</style>