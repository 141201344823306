import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: "",
    openid: "",
    userid: "",
    foodData: {},
    newlistDataA: []
    // aaa:false,
    // bbb:false
  },
  mutations: {
    setNewlistDataA(state, userStatus) {
      console.log('userStatus',userStatus)
        state.newlistDataA = userStatus;
        console.log('state.newlistDataA',state.newlistDataA)
        if(userStatus.length){
          // location.reload();
        }
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_OPENID: (state, openid) => {
      state.openid = openid
    },
    SET_USERID: (state, userid) => {
      state.userid = userid
    },
    SET_FoodData: (state, data) => {
      state.foodData = data
    },
  },
  getters,
  actions: {

  },
  modules: {

  },
  plugins: [
    createPersistedState({
        storage: {
            getItem: (key) => localStorage.getItem(key),
            setItem: (key, value) => localStorage.setItem(key, value),
            removeItem: (key) => localStorage.removeItem(key)
        }
    })
]
})
