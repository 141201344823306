<template>
    <div class='splitline'></div>
</template>

<script>

export default {
  name: 'splitline',
  components: {

  },
  data () {
    return {

    }
  },
  props: {

  },
  created () {

  },
  mounted () {

  },
  methods: {

  },
  watch: {

  },
  computed: {

  }
}
</script>
<style lang='scss' scoped>
.splitline {
    width: 100%;
    height: 1px;
    background: #D0CEDB;
    margin: 25px auto;
}
</style>
