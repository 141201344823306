<template>
  <div id="app">
    <!-- 导航头 -->
    <!-- <navhead v-if="tabHeadShow" :goback="goback" :title="title"></navhead> -->
    <router-view :class="tabHeadShow ? 'navBarTop' : ''" />
    <!-- 底部导航栏 -->
    <!-- <tab-bar v-if="tabBarShow"></tab-bar> -->
  </div>
</template>
<script>
import tabBar from './components/tabbar'
import navhead from './components/navhead'
export default {
  components: {
    tabBar,
    navhead
  },
  data () {
    return {
      tabBarShow: true,
      tabHeadShow: false,
      goback: true,
      title: ''
    }
  },
  watch: {
    $route (to, from) {
      // console.log(to, '1111')
      this.title = to.meta.subTitle
      if (to.meta.tabBar) {
        this.tabBarShow = true
      } else {
        this.tabBarShow = false
      }

      if (to.meta.tabHead) {
        this.tabHeadShow = true
      } else {
        this.tabHeadShow = false
      }
    }
  }
}
</script>
<style lang="scss">
  .pdfHeight{
    height:842px;
    // border:1px solid #000;
  }
  //生理
  .pdfHeightA{
    // height:1159px;
    // width:820px;
    height:842px;
    width: 595px;
    // border:1px solid #000;
  }
  .pageSize{
    position: relative;
    box-sizing: border-box;
    width: 1190px !important;
    height: 1683px !important;
    // border:1px solid #000;
  }

body {
  font-size: 10px;
  height: 100vh;
  width: 100%;
  font-family: MicrosoftYaHei;
}
div {
  box-sizing: border-box;
}
#app {
  height: 100%;
  width: 100%;

}
* {
  margin: 0;
  padding: 0;
}
.clearfix:before, .clearfix:after{
  content:"";
  display:table;
}
.clearfix:after{
    clear:both;
}

.clearfix{
    zoom: 1;
   /*IE6清除浮动的方式 只有IE6-IE7执行，其他浏览器不执行*/
}

img {
  display: block;
}
.img {
    width: 100%;
    height: 100%;
}
.navBarTop {
  min-height: 100vh;
  overflow: auto;
  // padding-top: 55px !important;
}
.textOvf {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: transparent;
}
//公共样式
.a_c{
    display: flex;
    align-items: center;
}
.a_b{
    display: flex;
	justify-content: space-between;
    align-items: center;
}
.a_j{
    display: flex;
    justify-content: center;
}
.a_flex{
    display: flex;
    justify-content: center;
    align-items: center;
}
.wh100{
    width: 100%;
    height: 100%;
}
.zzc {
    background-color: rgba(0,0,0,0.7);
    /* 使用固定定位让元素撑满全屏 */
    position: fixed;
    width: 100vw;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
}
.h30{
    height: 30rpx;
}

</style>
