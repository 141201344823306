/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal (path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

export function sortArr (arr, str) {
  var _arr = []
  var _t = []
  // 临时的变量
  var _tmp
  // 按照特定的参数将数组排序将具有相同值得排在一起
  arr = arr.sort(function (a, b) {
    var s = a[str]
    var t = b[str]

    return s < t ? -1 : 1
  })
  if (arr.length) {
    _tmp = arr[0][str]
  }
  // 将相同类别的对象添加到统一个数组
  for (var i in arr) {
    if (arr[i][str] === _tmp) {
      _t.push(arr[i])
    } else {
      _tmp = arr[i][str]
      _arr.push(_t)
      _t = [arr[i]]
    }
  }
  // 将最后的内容推出新数组
  _arr.push(_t) // 获取到所有整合数据
  // 最后整合
  // var integration = [];
  // _arr.forEach((item) => {
  //     var eee = { name: "", data: [] };
  //     item.forEach((self) => {
  //         if (eee.name != self.payType) {
  //             eee.name = self.payType;
  //             eee.data.push(self.money);
  //         } else {
  //             eee.data.push(self.money);
  //         }
  //     });
  //     integration.push(eee);
  // });

  return _arr
}

export function getUrlParams (param) {
  const url = this.$route.query // 获取url中"?"符后的字串
  const query = url[param]
  return query
}

export function unique (arr, val) {
  // 从小到大排序
  arr.sort(function (a, b) {
    return a.Ex1 - b.Ex1
  })
  // 去重
  const res = new Map()
  return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1))

  // for (var i = 0; i < arr.length - 1; i++) {
  //     for (var j = i + 1; j < arr.length; j++) {
  //         if (arr[i][val] == arr[j][val]) {
  //             arr.splice(j, 1);
  //             //因为数组长度减小1，所以直接 j++ 会漏掉一个元素，所以要 j--
  //             j--;
  //         }
  //     }
  // }
  // return arr;
}
export function current (t) {
  const date = t? new Date(t):new Date()
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()

  month = (month > 9) ? month : ('0' + month)
  day = (day < 10) ? ('0' + day) : day
  const today = year + '-' + month + '-' + day
  return today
}

export function timeSlot (step) { //  step = 间隔的分钟
  var date = new Date()
  date.setHours('00') // 时分秒设置从零点开始
  date.setSeconds('00')
  date.setUTCMinutes('00')
  // console.log(date.getHours())
  // console.log(date.getSeconds())
  // console.log(new Date(date.getTime()))

  var arr = []; var timeArr = []
  var slotNum = 24 * 60 / step // 算出多少个间隔
  for (var f = 0; f < slotNum; f++) { //  stepM * f = 24H*60M
    // arr.push(new Date(Number(date.getTime()) + Number(step*60*1000*f)))   //  标准时间数组
    var time = new Date(Number(date.getTime()) + Number(step * 60 * 1000 * f)) // 获取：零点的时间 + 每次递增的时间
    var hour = ''; var sec = ''
    time.getHours() < 10 ? hour = '0' + time.getHours() : hour = time.getHours() // 获取小时
    time.getMinutes() < 10 ? sec = '0' + time.getMinutes() : sec = time.getMinutes() // 获取分钟
    timeArr.push(hour + ':' + sec)
  }
  return timeArr
}

export function ChangeHourMinutestr (str) {
  if (str !== '0' && str !== '' && str !== null) {
    // return ((Math.floor(str / 60)).toString().length < 2 ? "0" + (Math.floor(str / 60)).toString() :
    // (Math.floor(str / 60)).toString()) + ":" + ((str % 60).toString().length < 2 ? "0" + (str % 60).toString() : (str % 60).toString());
    const h = (Math.floor(str / 60)).toString()
    const m = (str % 60).toString().length < 2 ? '0' + (str % 60).toString() : (str % 60).toString()
    return [h, m]
  } else {
    return ''
  }
}
