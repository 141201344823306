import Vue from 'vue'
import VueRouter from 'vue-router'

// 首页
const coreHome = () => import('../views/core/home.vue')
const loveHome = () => import('../views/love/home.vue')
const filialPiety = () => import('../views/filialPiety/home.vue')
const shop = () => import('../views/shop/home.vue')
const him5 = () => import('../views/him5/index.vue')
const deviceScan = () => import('../views/him5/deviceScan.vue')

// 首页我的健康二级界面
const myhealthSL = () => import('../views/core/myHealth/myhealthSL.vue')
const myhealthSLOne = () => import('../views/core/myHealth/myhealthSLOne.vue')
const healthLook = () => import('../views/core/myHealth/healthLook.vue')
const myHealingPlan = () => import('..//views/core/myHealth/MyHealingPlan.vue')
const myCe = () => import('..//views/core/myHealth/myCe.vue')
// 个人中心
const personalCenter = () => import('../views/personalCenter/index.vue')
// 用户信息修改
const updateUserInfo = () => import('../views/personalCenter/updateUserInfo.vue')

// 蓝牙功能页
const sleep = () => import('../views/secondLevel/sleep.vue')
const bloodOxygen = () => import('../views/secondLevel/bloodOxygen.vue')
const heartRate = () => import('../views/secondLevel/heartRate.vue')
const temperature = () => import('../views/secondLevel/temperature.vue')
const step = () => import('../views/secondLevel/step.vue')
const blood = () => import('../views/secondLevel/blood.vue')
const pressure = () => import('../views/secondLevel/pressure.vue')
// 我的健康
const sleepPressure = () => import('../views/secondLevel/sleepPressure.vue')
const heartRateBloodPressure = () => import('../views/secondLevel/heartRateBloodPressure.vue')
const newEcharts = () => import('../views/secondLevel/newEcharts.vue')
// 月度走势
const monthHealth = () => import('../views/monthlyTrend/monthHealth.vue')
const sleepTrend = () => import('../views/monthlyTrend/sleepTrend.vue')
const bloodOxTrend = () => import('../views/monthlyTrend/bloodOxTrend.vue')
const heartTrend = () => import('../views/monthlyTrend/heartTrend.vue')
const pressureTrend = () => import('../views/monthlyTrend/pressureTrend.vue')
const bloodTrend = () => import('../views/monthlyTrend/bloodTrend.vue')
// 食疗问卷页
const fillInInfo = () => import('../views/dietTyerapy/fillInInfo.vue')
const physiqueTest = () => import('../views/dietTyerapy/physiqueTest.vue')
const healthTest = () => import('../views/dietTyerapy/healthTest.vue')
const summary = () => import('../views/dietTyerapy/summary.vue')
const recipes = () => import('../views/dietTyerapy/recipes.vue')
const recipesPdf = () => import('../views/reportPdf/recipesPdf.vue')
const recipesPdfNew = () => import('../views/reportPdf/recipesPdfNew.vue')
const uitimateHealthPdf = () => import('../views/reportPdf/uitimateHealthPdf.vue')
const inspectionReportPdf = () => import('../views/reportPdf/inspectionReportPdf.vue')
const testReport = () => import('../views/reportPdf/testReport.vue')
const funReport = () => import('../views/reportPdf/funReport.vue')
const analysisReport = () => import('../views/reportPdfA/analysisReport.vue')
const proposalReport = () => import('../views/reportPdfB/analysisReport.vue')

// 订单问卷页
const orderInvestigate = () => import('../views/order/orderInvestigate.vue')
const orderList = () => import('../views/orderList/list.vue')
// 检测报告
const iFeelReport = () => import('../views/report/iFeelReport.vue')
const coreReport = () => import('../views/report/coreReport.vue')
const mapReport = () => import('../views/report/mapReport.vue')
const adiponectin = () => import('../views/report/adiponectin.vue')
const organicAcid = () => import('../views/report/organicAcid.vue')
const bodyAnalysis = () => import('../views/report/bodyAnalysis.vue')
// 检测报告Pdf
const coreReportCopy = () => import('../views/reportPdf/coreReportCopy.vue')
const iFeelReportCopy = () => import('../views/reportPdf/iFeelReportCopy.vue')
const iFeelReportCopyA = () => import('../views/reportPdf/iFeelReportCopyA.vue')
const adiponectinPdf = () => import('../views/reportPdf/adiponectinPdf.vue')
const organicAcidPdf = () => import('../views/reportPdf/organicAcidPdf.vue')
const instructionsPdf = () => import('../views/reportPdf/instructionsPdf.vue')
const instructionsPdfOffline = () => import('../views/reportPdf/instructionsPdfOffline.vue')
const instructionsPdfOfflineyssd = () => import('../views/reportPdf/instructionsPdfOfflineyssd.vue')
const instructionsPdfOfflineyssdNew = () => import('../views/reportPdf/instructionsPdfOfflineyssdNew.vue')
const instructionsPdfOnline = () => import('../views/reportPdf/instructionsPdfOnline.vue')
const bodyAnalysisPdf = () => import('../views/reportPdf/bodyAnalysisPdf.vue')
const healthAnalysisPdf = () => import('../views/reportPdf/healthAnalysisPdf.vue')
const healthAnalysisCopyPdf = () => import('../views/reportPdf/healthAnalysisCopyPdf.vue')
const healingIfeelReportCopy = () => import('../views/reportPdf/healingIfeelReportCopy.vue')
const immuneAgePdf = () => import('../views/reportPdf/immuneAgePdf.vue')
const physiologyPdf = () => import('../views/reportPdf/physiologyPdf.vue')
const healthThemeListPdf = () => import('../views/reportPdf/healthThemeListPdf.vue')
// 个性化项目pdf
const personalUltimatePdf = () => import('../views/reportPdf/personalUltimatePdf.vue')
const personaPhysiologyPdf = () => import('../views/reportPdf/personaPhysiologyPdf.vue')
const personaImmuneAgePdf = () => import('../views/reportPdf/personaImmuneAgePdf.vue')
const personaSenility = () => import('../views/reportPdf/personaSenility.vue')
const productDescription = () => import('../views/reportPdf/productDescription.vue')

// 预览Pdf
const previewPdf = () => import('../views/previewPdf/index.vue')

// 疗愈世界--睡眠调频所
const sleepStation = () => import('../views/healingTheWorld/sleepStation.vue')
const trauma = () => import('../views/healingTheWorld/trauma.vue')
const recuperation = () => import('../views/healingTheWorld/recuperation.vue')
const fatRemoval = () => import('../views/healingTheWorld/fatRemoval.vue')
const alopecia = () => import('../views/healingTheWorld/alopecia.vue')
const desireDharma = () => import('../views/healingTheWorld/desireDharma.vue')
const EMDR = () => import('../views/healingTheWorld/EMDR.vue')

// 我的界面报告生成
const moodAndHealth = () => import('../views/personalCenter/report/moodAndHealth.vue')
const dietReport = () => import('../views/personalCenter/report/dietReport.vue')
const emotionAnalysis = () => import('../views/personalCenter/report/emotionAnalysis.vue')
const qrcode = () => import('../views/qrCode/qrcode.vue')
const code1 = () => import('../views/qrCode/code1.vue')
//  新增报告页面
const instructionsPdfOnlinexinyou = () => import('../views/reportPdf/instructionsPdfOnlinexinyou.vue')
const instructionsPdfOfflinexinyou = () => import('../views/reportPdf/instructionsPdfOfflinexinyou.vue')
const userInformation = () => import('../views/userInformation/index.vue')
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/core'
  },
  {
    path: '/core',
    component: coreHome,
    meta: {
      tabBar: true
      // tabHead: true,
    },
    children: [
    ]
  },
  {
    path: '/instructionsPdfOfflinexinyou',
    component: instructionsPdfOfflinexinyou,
    meta: {
      subTitle: '产品线下说明书新'
    }
  },

  {
    path: '/userInformation',
    component: userInformation,
    meta: {
      subTitle: '个人信息趋势图'
    }
  },
  {
    path: '/instructionsPdfOnlinexinyou',
    component: instructionsPdfOnlinexinyou,
    meta: {
      subTitle: '产品线上说明书新'
    }
  },
  {
    path: '/myhealthSL',
    name: 'myhealthSL',
    meta: {
      tabHead: true
    },
    component: myhealthSL
  },
  {
    path: '/myhealthSLOne',
    name: 'myhealthSLOne',
    meta: {
      tabHead: true
    },
    component: myhealthSLOne
  },
  {
    path: '/healthLook',
    name: 'healthLook',
    meta: {
      tabHead: true
    },
    component: healthLook
  },
  {
    path: '/myCe',
    name: 'myCe',
    meta: {
      tabHead: true
    },
    component: myCe
  },
  {
    path: '/myHealingPlan',
    name: 'myHealingPlan',
    meta: {
      tabHead: true
    },
    component: myHealingPlan
  },
  {
    path: '/love',
    name: 'love',
    meta: {
      tabBar: true
    },
    component: loveHome
  },
  {
    path: '/filialPiety',
    name: 'filialPiety',
    meta: {
      tabBar: true
    },
    component: filialPiety
  },
  {
    path: '/shop',
    name: 'shop',
    component: shop
  },
  {
    path: '/personalCenter',
    name: 'personalCenter',
    meta: {
      tabBar: true
    },
    component: personalCenter
  },
  {
    path: '/updateUserInfo',
    name: 'updateUserInfo',
    meta: {
      tabHead: true,
      subTitle: '修改信息'
    },
    component: updateUserInfo
  },
  {
    path: '/moodAndHealth',
    name: 'moodAndHealth',
    meta: {
      tabHead: true
    },
    component: moodAndHealth
  },
  {
    path: '/dietReport',
    name: 'dietReport',
    meta: {
      tabHead: true,
      subTitle: ''
    },
    component: dietReport
  },
  {
    path: '/emotionAnalysis',
    name: 'emotionAnalysis',
    meta: {
      tabHead: true
    },
    component: emotionAnalysis
  },
  {
    path: '/monthHealth',
    component: monthHealth,
    meta: {
      tabHead: true
    }
  },
  {
    path: '/sleep',
    component: sleep,
    meta: {
      tabHead: true,
      subTitle: '睡眠'
    }
  },
  {
    path: '/sleepTrend',
    component: sleepTrend,
    meta: {
      tabHead: true,
      subTitle: '睡眠走势'
    }
  },
  {
    path: '/bloodOxygen',
    component: bloodOxygen,
    meta: {
      tabHead: true,
      subTitle: '血氧饱和度'
    }
  },
  {
    path: '/bloodOxTrend',
    component: bloodOxTrend,
    meta: {
      tabHead: true,
      subTitle: '血氧饱和度走势'
    }
  },
  {
    path: '/heartRate',
    component: heartRate,
    meta: {
      tabHead: true,
      subTitle: '心率'
    },
    route: heartTrend
  },
  {
    path: '/heartRateBloodPressure',
    component: heartRateBloodPressure,
    meta: {
      tabHead: true,
      subTitle: '心率走势'
    }
  },
  {
    path: '/newEcharts',
    component: newEcharts,
    meta: {
      tabHead: true,
      subTitle: '测试'
    }
  },
  {
    path: '/pressure',
    name: 'Pressure',
    meta: {
      tabHead: true,
      subTitle: '压力'
    },
    component: pressure
  },
  {
    path: '/sleepPressure',
    name: 'sleepPressure',
    meta: {
      tabHead: true,
      subTitle: '睡眠压力'
    },
    component: sleepPressure
  },
  {
    path: '/heartRateBloodPressure',
    name: 'heartRateBloodPressure',
    meta: {
      tabHead: true,
      subTitle: '心率血压'
    },
    component: sleepPressure
  },
  {
    path: '/newEcharts',
    name: 'newEcharts',
    meta: {
      tabHead: true,
      subTitle: '测试'
    },
    component: sleepPressure
  },

  {
    path: '/pressureTrend',
    component: pressureTrend,
    meta: {
      tabHead: true,
      subTitle: '压力走势'
    }
  },
  {
    path: '/blood',
    name: 'Blood',
    meta: {
      tabHead: true,
      subTitle: '血压'
    },
    component: blood
  },
  {
    path: '/bloodTrend',
    component: bloodTrend,
    meta: {
      tabHead: true,
      subTitle: '血压走势'
    }
  },
  {
    path: '/step',
    name: 'Step',
    meta: {
      tabHead: true,
      subTitle: '步数'
    },
    component: step
  },
  {
    path: '/temperature',
    component: temperature,
    meta: {
      tabHead: true,
      subTitle: '温度'
    }
  },
  {
    path: '/fillInInfo',
    component: fillInInfo,
    meta: {
      tabHead: true
      // subTitle: '填写信息'
    }
  },
  {
    path: '/physiqueTest',
    component: physiqueTest,
    meta: {
      tabHead: true
      // subTitle: '填写信息'
    }
  },
  {
    path: '/healthTest',
    component: healthTest,
    meta: {
      tabHead: true
    }
  },
  {
    path: '/summary',
    component: summary,
    meta: {
      tabHead: true
    }
  },
  {
    path: '/recipes',
    component: recipes,
    meta: {
      // tabHead: true,
    }
  },
  {
    path: '/recipesPdf',
    component: recipesPdf,
    meta: {
      // tabHead: true,
    }
  },
  {
    path: '/recipesPdfNew',
    component: recipesPdfNew,
    meta: {
      // tabHead: true,
    }
  },
  {
    path: '/uitimateHealthPdf',
    component: uitimateHealthPdf,
    meta: {
      // tabHead: true,
    }
  },
  {
    path: '/inspectionReportPdf',
    component: inspectionReportPdf,
    meta: {
      // tabHead: true,
    }
  },
  {
    path: '/precisePdfPage',
    component: testReport,
    meta: {
      // tabHead: true,
    }
  },
  {
    path: '/funReport',
    component: funReport,
    meta: {
      // tabHead: true,
    }
  },
  {
    path: '/analysisReport',
    component: analysisReport,
    meta: {
      // tabHead: true,
    }
  },
  {
    path: '/proposalReport',
    component: proposalReport,
    meta: {
      // tabHead: true,
    }
  },

  {
    path: '/previewPdf',
    component: previewPdf,
    meta: {
      subTitle: '报告预览'
    }
  },
  {
    path: '/iFeelReport',
    component: iFeelReport,
    meta: {
      subTitle: '全身功能器官分析'
    }
  },
  {
    path: '/iFeelReportCopy',
    component: iFeelReportCopy,
    meta: {
      subTitle: '全身功能器官分析'
    }
  },
  {
    path: '/iFeelReportCopyA',
    component: iFeelReportCopyA,
    meta: {
      subTitle: '全身功能器官分析COPY'
    }
  },
  {
    path: '/healingIfeelReportCopy',
    component: healingIfeelReportCopy,
    meta: {
      subTitle: '全身器官功能分析1'
    }
  },
  {
    path: '/coreReport',
    component: coreReport,
    meta: {
      subTitle: '储备能力分析'
    }
  },
  {
    path: '/coreReportCopy',
    component: coreReportCopy,
    meta: {
      subTitle: '储备能力分析'
    }
  },
  {
    path: '/adiponectin',
    component: adiponectin,
    meta: {
      subTitle: '脂联素报告'
    }
  },
  {
    path: '/adiponectinPdf',
    component: adiponectinPdf,
    meta: {
      subTitle: '脂联素报告'
    }
  },
  {
    path: '/him5',
    component: him5,
    meta: {
      subTitle: '表单'
    }
  },
  {
    path: '/deviceScan',
    component: deviceScan,
    meta: {
      subTitle: '设备二维码扫描',
      title: '二维码扫描'
    }
  },
  {
    path: '/organicAcid',
    component: organicAcid,
    meta: {
      subTitle: '尿液有机酸'
    }
  },
  {
    path: '/organicAcidPdf',
    component: organicAcidPdf,
    meta: {
      subTitle: '尿液有机酸'
    }
  },
  {
    path: '/physiologyPdf',
    component: physiologyPdf,
    meta: {
      subTitle: '生理年龄分析'
    }
  },
  {
    path: '/personalUltimatePdf',
    component: personalUltimatePdf,
    meta: {
      subTitle: '终极'
    }
  },
  {
    path: '/personaPhysiologyPdf',
    component: personaPhysiologyPdf,
    meta: {
      subTitle: '生理'
    }
  },
  {
    path: '/personaImmuneAgePdf',
    component: personaImmuneAgePdf,
    meta: {
      subTitle: '免疫'
    }
  },
  {
    path: '/personaSenility',
    component: personaSenility,
    meta: {
      subTitle: '衰老'
    }
  },
  {
    path: '/productDescription',
    component: productDescription,
    meta: {
      subTitle: '产品说明'
    }
  },

  {
    path: '/healthThemeListPdf',
    component: healthThemeListPdf,
    meta: {
      subTitle: '健康主题检测指标清单'
    }
  },

  {
    path: '/qrcode',
    component: qrcode,
    meta: {
      subTitle: '二维码生成'
    }
  },
  {
    path: '/code1',
    component: code1,
    meta: {
      subTitle: 'cc'
    }
  },
  {
    path: '/immuneAgePdf',
    component: immuneAgePdf,
    meta: {
      subTitle: '免疫年龄分析'
    }
  },
  {
    path: '/instructionsPdf',
    component: instructionsPdf,
    meta: {
      subTitle: '产品说明书'
    }
  },
  {
    path: '/instructionsPdfOnline',
    name: 'instructionsPdfOnline',
    meta: {
      subTitle: '产品线上说明书'
    },
    component: instructionsPdfOnline
  },
  {
    path: '/instructionsPdfOffline',
    component: instructionsPdfOffline,
    meta: {
      subTitle: '产品线下说明书'
    }
  },

  {
    path: '/instructionsPdfOfflineyssd',
    component: instructionsPdfOfflineyssd,
    meta: {
      subTitle: '产品线下说明书益生时代'
    }
  },
  {
    path: '/instructionsPdfOfflineyssdNew',
    component: instructionsPdfOfflineyssdNew,
    meta: {
      subTitle: '产品线下说明书益生时代新'
    }
  },
  {
    path: '/bodyAnalysis',
    component: bodyAnalysis,
    meta: {
      subTitle: '身体分析'
    }
  },
  {
    path: '/bodyAnalysisPdf',
    component: bodyAnalysisPdf,
    meta: {
      subTitle: '身体分析'
    }
  },
  {
    path: '/mapReport',
    component: mapReport,
    meta: {
      subTitle: '身体地图报告'
    }
  },
  {
    path: '/healthAnalysisPdf',
    component: healthAnalysisPdf,
    meta: {
      subTitle: '健康分析'
    }
  },
  {
    path: '/healthAnalysisCopyPdf',
    component: healthAnalysisCopyPdf,
    meta: {
      subTitle: '健康分析'
    }
  },
  {
    path: '/orderInvestigate',
    name: 'orderInvestigate',
    component: orderInvestigate
  },
  {
    path: '/orderList',
    name: 'orderList',
    component: orderList
  },
  {
    path: '/sleepStation',
    name: 'sleepStation',
    meta: {
      subTitle: '睡眠调频所'
    },
    component: sleepStation
  },
  {
    path: '/trauma',
    name: 'trauma',
    meta: {
      subTitle: '创伤和解庭'
    },
    component: trauma
  },
  {
    path: '/recuperation',
    name: 'recuperation',
    meta: {
      subTitle: '功能疗养院'
    },
    component: recuperation
  },
  {
    path: '/fatRemoval',
    name: 'fatRemoval',
    meta: {
      subTitle: '解压甩脂站'
    },
    component: fatRemoval
  },
  {
    path: '/alopecia',
    name: 'alopecia ',
    meta: {
      subTitle: '压力性脱发'
    },
    component: alopecia
  },
  {
    path: '/desireDharma',
    name: 'desireDharma',
    meta: {
      subTitle: '心愿达摩院'
    },
    component: desireDharma
  },
  {
    path: '/EMDR',
    name: 'EMDR',
    meta: {
      subTitle: 'EMDR'
    },
    component: EMDR
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
