import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import Vconsole from 'vconsole'
import './util/rem'
import './icons'
import './util/jweixin1.3.2'
//全部引入
import * as echarts from 'echarts';
import 'vant/lib/index.css'


import {PullRefresh , Dialog, Switch, Loading, Calendar, Progress, Rate, Form, Button, Toast, Field, Popup, Picker, RadioGroup, Radio, Icon, Overlay, CellGroup, Cell, Checkbox, CheckboxGroup, Grid, GridItem, Area, DatetimePicker, Circle } from 'vant'

// 导航头，导航栏组件
import splitline from './components/splitline.vue'
import titlenav from './components/titlenav.vue'
import { getUrlParams } from '@/util/validate'

// let vConsole = new Vconsole()
Vue.use(Loading)
Vue.use(Calendar)
Vue.use(Switch)
Vue.use(Rate)
Vue.use(Button)
Vue.use(Progress)
Vue.use(Toast)
Vue.use(Form)
Vue.use(Field)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Icon)
Vue.use(Overlay)
Vue.use(CellGroup)
Vue.use(Cell)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Area)
Vue.use(DatetimePicker)
Vue.use(Circle)
Vue.use(Dialog)
Vue.use(Loading)
Vue.use(Circle)
Vue.use(Toast)
Vue.use(Form)
Vue.use(Field)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Icon)
Vue.use(Overlay)
Vue.use(CellGroup)
Vue.use(Cell)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(PullRefresh)
// Vue.use(vConsole);
Vue.component(splitline.name, splitline)
Vue.component(titlenav.name, titlenav)

const { domain, imgDomain } = require('./config.json')
Vue.prototype.$EventBus = new Vue()
Object.defineProperties(Vue.prototype, {
  $domain: {
    value: domain
  },
  $imgDomain: {
    value: imgDomain
  },
  $query: {
    value: getUrlParams
  }
})

router.beforeEach((to, from, next) => {
  to.meta.title && (document.title = to.meta.title)
  next()
})

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
