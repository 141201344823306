<template>
    <div class='tab_bar_item' @click="clickItem">
        <!-- active Img -->
        <!-- <div v-if="isActive">
            <slot name="img_active" />
        </div> -->
        <!-- Img -->
        <!-- <div v-else>
            <slot name="img" />
        </div> -->

        <!-- text -->
        <div :class="isActiveColor">
            <slot name="text" />
        </div>
    </div>
</template>

<script>
// import wx from '../../util/jweixin1.3.2'
import wx from '@/util/jweixin1.3.2'
export default {
  components: {},
  data () {
    return {

    }
  },
  props: {
    path: String
  },
  computed: {
    isActive () {
      return this.$route.path.includes(this.path)
    },
    isActiveColor () {
      return this.isActive ? 'isActive' : 'noActive'
    }
  },
  methods: {
    clickItem () {
      console.log(this.path)
      if (this.path == '/shop') {
        wx.miniProgram.navigateTo({
          url: '/pages/initialize/shopHome'
        })
        return
      }

      this.$router.push(this.path)
    }
  },
  watch: {

  }
}
</script>
<style lang='scss' scoped>
.tab_bar_item {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    .noActive {
        color: #898989;
    }
    .isActive {
        color: #9966FF;
        position: relative;
    }
    .isActive::after {
        content: "";
        width: 80%;
        height: 2px;
        background-color: #ff9999;
        border-radius: 2px;
        position: absolute;
        bottom: -2px;
        left: 10%;
    }
}
</style>
