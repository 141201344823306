function setRem() {
    const pageWidth = window.innerWidth;
    if(typeof pageWidth != "number") {
        if(document.compatMode == "CSS1Compat") {
            pageWidth =  document.documentElement.clientWidth;
        } else {
            pageWidth =  document.body.clientWidth;
        }
    }

    if(pageWidth <= 750){
        const baseSize = 75;
        const scale = document.documentElement.clientWidth / pageWidth;
        document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
    } else if (pageWidth > 750 && pageWidth <= 1200) {
        const baseSize = 85;
        const scale = document.documentElement.clientWidth / pageWidth;
        document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
    } else {
        const baseSize = 100;
        const scale = document.documentElement.clientWidth / 1920;
        document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
    }
}

setRem();

window.onresize = function (){
    setRem();
}