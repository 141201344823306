<template>
    <div class='tab_bar'>
        <tab-bar-item path="/core">
            <div slot="text">核心</div>
        </tab-bar-item>
        <tab-bar-item path="/love">
            <div slot="text">爱心</div>
        </tab-bar-item>
        <tab-bar-item path="/shop">
            <div slot="text">健康城邦</div>
        </tab-bar-item>
        <tab-bar-item path="/filialPiety">
            <div slot="text">孝心</div>
        </tab-bar-item>
        <tab-bar-item path="/personalCenter">
            <div slot="text">我的</div>
        </tab-bar-item>
    </div>
</template>

<script>
import TabBarItem from './TabBarItem.vue'
export default {
  components: {
    TabBarItem
  },
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  },
  watch: {

  },
  computed: {

  }
}
</script>
<style lang='scss' scoped>
.tab_bar {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 60px;
    background-image: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/tabBarBga.png');
    background-size: cover;
    z-index: 9999;
}
</style>
